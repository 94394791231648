import dayjs from 'dayjs'
import i18n from '@/libs/i18n'

let tz = dayjs.tz.guess()
if (JSON.parse(localStorage.getItem('userInfo')) && JSON.parse(localStorage.getItem('userInfo')).timezone) {
  tz = JSON.parse(localStorage.getItem('userInfo')).timezone
}
dayjs.tz.setDefault(tz)

const transformDateToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('DD.MM.YYYY')
const transformDatetimeToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('DD.MM.YYYY HH:mm')
const transformTimeToHumanReadablePretty = date => dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('HH:mm')
const humanizeDuration = durationInMinutes => {
  const h = Math.floor(durationInMinutes / 60)
  const m = durationInMinutes % 60
  if (!h && !m) return ''
  if (!h) return i18n.tc('shared.plural.minutes', m, { n: m })
  if (!m) return i18n.tc('shared.plural.hours', h, { n: h })

  return `${i18n.tc('shared.plural.hours', h, { n: h })} ${i18n.tc('shared.plural.minutes', m, { n: m })}`
}

const fixDateTimezone = (date, format = null) => {
  if (!date) {
    return null
  }

  if (format === null) {
    return dayjs(date).format()
  }

  return dayjs(date).format(format)
}

export {
  fixDateTimezone,
  transformDateToHumanReadablePretty,
  transformDatetimeToHumanReadablePretty,
  transformTimeToHumanReadablePretty,
  humanizeDuration,
}
