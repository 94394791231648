import calendarEvents from '@/http/requests/calendars/event'
import {
  cloneNested,
  parseRequestError,
  sort,
} from '@/helpers/helpers'
import { fixDateTimezone } from '@/helpers/dateTimeHelper'

const prepareEventDataToSend = event => {
  const data = cloneNested(event)

  data.startAt = fixDateTimezone(data.startAt)
  data.endAt = fixDateTimezone(data.endAt)
  if (data?.calendarEventSequence?.sequenceEndDate) {
    data.calendarEventSequence.sequenceStartDate = fixDateTimezone(data.startAt, 'YYYY-MM-DD')
    data.calendarEventSequence.sequenceEndDate = fixDateTimezone(data.calendarEventSequence.sequenceEndDate, 'YYYY-MM-DD')
  }

  const preparedCalendarEventCustomers = {}
  Object.values(data.calendarEventCustomerObjects).forEach(calendarEventCustomerObject => {
    let id = `new${calendarEventCustomerObject.customer.id}`
    if (calendarEventCustomerObject.id) {
      id = calendarEventCustomerObject.id
    }
    preparedCalendarEventCustomers[id] = { customer: calendarEventCustomerObject.customer.id }
  })
  data.calendarEventCustomers = preparedCalendarEventCustomers

  return data
}

export default {
  activateEditing: ({ commit }, calendarEventId) => new Promise(() => {
    commit('SET_EVENT_ID', calendarEventId)
    commit('SET_FORM_VISIBLE', true)
    commit('SET_PROCESSING', true)
  }),

  prepareEventEditing: ({ commit }, calendarEventId) => new Promise((resolve, reject) => {
    commit('CLEAR_EVENT')
    calendarEvents.getCalendarEvent(calendarEventId)
      .then(response => {
        const { data } = response

        data.calendarEventCustomers = sort(data.calendarEventCustomers)

        if (data.service) {
          data.serviceObject = data.service
          data.service = data.service.id
        }

        if (data.calendar) {
          data.calendarObject = data.calendar
          data.calendar = data.calendar.id
        }

        if (data.script) {
          data.scriptObject = data.script
          data.script = data.script.id
        }

        data.calendarEventCustomerObjects = []
        if (data.calendarEventCustomers.length > 0) {
          data.calendarEventCustomerObjects = data.calendarEventCustomers
          data.calendarEventCustomers = data.calendarEventCustomers.map(calendarEventCustomer => calendarEventCustomer.customer.id)
        }

        data.startAt = fixDateTimezone(data.startAt, 'YYYY-MM-DD HH:mm')
        data.endAt = fixDateTimezone(data.endAt, 'YYYY-MM-DD HH:mm')

        if (data.calendarEventSequence) {
          data.calendarEventSequence.sequenceEndDate = fixDateTimezone(data.calendarEventSequence.sequenceEndDate, 'YYYY-MM-DD HH:mm')
          data.calendarEventSequence.sequenceStartDate = fixDateTimezone(data.calendarEventSequence.sequenceStartDate, 'YYYY-MM-DD HH:mm')
        }

        commit('SET_EVENT', data)
        resolve(data)
      })
      .catch(error => {
        parseRequestError(error)
        reject(error)
      })
      .finally(() => {})
  }),

  addCalendarEvent: ({ state, commit }) => new Promise((resolve, reject) => {
    commit('SET_PROCESSING', true)

    const data = prepareEventDataToSend(state.event)

    const params = {
      cancelConflictingEvents: state.cancelConflictingEvents,
    }

    calendarEvents.postCalendarEvent(data, params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        commit('SET_FORM_VISIBLE', false)
      })
  }),

  updateCalendarEvent: ({ state, commit }) => new Promise((resolve, reject) => {
    commit('SET_PROCESSING', true)

    const data = prepareEventDataToSend(state.event)

    const params = {
      updateFutureEvents: state.updateFutureEvents,
      cancelConflictingEvents: state.cancelConflictingEvents,
    }

    calendarEvents.patchCalendarEvent(data.id, data, params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .finally(() => {
        commit('SET_FORM_VISIBLE', false)
      })
  }),

  cancelCalendarEvent: (_, payload) => new Promise((resolve, reject) => {
    const params = {}

    if (payload.initiatedByCustomer) {
      params.initiatedByCustomer = payload.initiatedByCustomer
    }

    if (payload.cancelFutureEvents) {
      params.cancelFutureEvents = payload.cancelFutureEvents
    }

    if (payload.ignoreLateCancellationFine) {
      params.ignoreLateCancellationFine = payload.ignoreLateCancellationFine
    }

    calendarEvents.cancelCalendarEvent(
      payload.calendarEventId,
      payload.customerId,
      params,
    )
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  cleanUpEditing: ({ commit }) => {
    commit('SET_EVENT_ID', null)
    commit('CLEAR_EVENT')
    commit('SET_PROCESSING', false)
    commit('SET_FORM_VISIBLE', false)
    commit('SET_UPDATE_FUTURE_EVENTS', false)
    commit('SET_CANCEL_CONFLICTING_EVENTS', false)
    commit('SET_COLLISIONS', [])
  },

  fetchCalendarEventCollisions: ({ commit }, payload) => new Promise((resolve, reject) => {
    commit('SET_COLLISIONS', [])
    calendarEvents.getCalendarEventCollisions(payload)
      .then(response => {
        commit('SET_COLLISIONS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
