import modulePublicPaymentsState from '@/store/publicStore/payments/modulePublicPaymentsState'
import modulePublicPaymentsMutations from '@/store/publicStore/payments/modulePublicPaymentsMutations'
import modulePublicPaymentsActions from '@/store/publicStore/payments/modulePublicPaymentsActions'
import modulePublicPaymentsGetters from '@/store/publicStore/payments/modulePublicPaymentsGetters'

export default {
  namespaced: true,
  state: { ...modulePublicPaymentsState },
  mutations: { ...modulePublicPaymentsMutations },
  actions: { ...modulePublicPaymentsActions },
  getters: { ...modulePublicPaymentsGetters },
}
