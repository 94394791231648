import axios from '@/libs/axios'
import router from '@/router'

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init(store) {
    axios.interceptors.response.use(response => response, error => {
      const { config, response } = error
      const originalRequest = config

      if (!!response && [401].includes(response.status) && ['An authentication exception occurred.', 'Invalid JWT Refresh Token', 'JWT Refresh Token Not Found'].includes(response.data.message)) {
        localStorage.setItem('previousPath', router.currentRoute.path)
        store.dispatch('auth/logout').then(() => router.push({ name: 'login' }))
      }

      if (!!response && response.status === 401 && ['Expired JWT Token'].includes(response.data.message)) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          store.dispatch('auth/fetchAccessTokenByRefreshToken')
            .then(accessToken => {
              onAccessTokenFetched(accessToken)
              store.dispatch('auth/fetchUserInfo')
            })
            .finally(() => {
              isAlreadyFetchingAccessToken = false
            })
        }

        return new Promise(resolve => {
          addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `Bearer ${accessToken.data.token}`
            resolve(axios(originalRequest))
          })
        })
      }

      if (response) {
        return Promise.reject(response.data)
      }

      return Promise.reject(new Error('Unknown connection error'))
    })
  },
  login(payload) {
    return axios.post('/auth/token', payload, {})
  },
  register(payload) {
    return axios.post('/register', payload, {})
  },
  registerInOrganization(organizationId, payload) {
    return axios.post(`/register/in-organization/${organizationId}`, payload, {})
  },
  confirmRegistration(payload) {
    return axios.post(`/register/${payload.userId}/confirm/${payload.confirmationCode}`, payload, {})
  },
  forgotPassword(payload) {
    return axios.post('/auth/password-change-request', payload, {})
  },
  resetPassword(userId, payload) {
    return axios.patch(`/auth/change-password/${userId}`, payload, {})
  },
  userInfo() {
    return axios.get('/user/me')
  },
  organizationUsers() {
    return axios.get('/user/me/organization/users')
  },
  userOrganizationInfo() {
    return axios.get('/user/me/organization')
  },
  refreshToken() {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    if (!tokens) {
      return new Promise((res, rej) => {
        if (localStorage.getItem('userInfo')) {
          localStorage.removeItem('userInfo')
        }
        if (localStorage.getItem('tokens')) {
          localStorage.removeItem('tokens')
        }
        router.push({ name: 'login' })
        rej(new Error('Logged Out'))
      })
    }
    return axios.post('/auth/token/refresh', { refreshToken: tokens.refreshToken })
  },
}
