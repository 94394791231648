import settlements from '@/http/requests/customers/settlements'

export default {
  fetchCustomerSettlements: ({ commit }) => new Promise((resolve, reject) => {
    settlements.getCustomerSettlements()
      .then(response => {
        const { data } = response
        commit('SET_CUSTOMER_SETTLEMENTS', data)
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchPaymentIntegrationsForOrganization: (_, organizationId) => new Promise((resolve, reject) => {
    settlements.getPaymentIntegrationsForOrganization(organizationId)
      .then(response => {
        const { data } = response
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchPaymentIntegrationsForCustomer: (_, customerId) => new Promise((resolve, reject) => {
    settlements.getPaymentIntegrationsForCustomer(customerId)
      .then(response => {
        const { data } = response
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchPaymentStatus: (_, paymentStatusId) => new Promise((resolve, reject) => {
    settlements.getPaymentStatus(paymentStatusId)
      .then(response => {
        const { data } = response
        resolve(data)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
