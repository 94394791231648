import axios from '@/libs/axios'
import booking from '@/http/requests/organization/booking'

export default {
  ...booking,
  patchOrganization(organizationId, payload) {
    return axios.patch(`/organizations/${organizationId}`, payload)
  },
  getUserById(userId) {
    return axios.get(`/organizations/users/${userId}`)
  },
  getUsersPaginated(payload) {
    return axios.get('/organizations/users', payload)
  },
  addUser(payload) {
    return axios.post('/organizations/users', payload)
  },
  patchUser(userId, payload) {
    return axios.patch(`/organizations/users/${userId}`, payload)
  },
}
