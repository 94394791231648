import role from '@/auth/role'

export default [
  {
    path: '/customer/settlements',
    name: 'customer-settlements',
    component: () => import('@/views/customer/settlements/Settlements.vue'),
    meta: {
      title: 'navigation.settlements',
      pageTitle: 'navigation.settlements',
      access: [role.student, role.guardian],
      breadcrumb: [
        {
          text: 'navigation.settlements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer/settlements/:customerId/pay',
    name: 'customer-settlements-pay',
    component: () => import('@/views/customer/settlements/payment/Payment'),
    meta: {
      title: 'navigation.payment',
      access: [role.student, role.guardian],
    },
  },
  {
    path: '/customer/settlements/payment/:paymentId/status',
    name: 'customer-settlements-payment-status',
    component: () => import('@/views/customer/settlements/payment/PaymentStatus'),
    meta: {
      title: 'navigation.payment-status',
      access: [role.student, role.guardian],
    },
  },
]
