import Vue from 'vue'
import VueRouter from 'vue-router'
import dayjs from 'dayjs'
import auth from '@/router/auth'
import error from '@/router/error'
import organization from '@/router/organization'
import customer from '@/router/customer'
import { $themeConfig } from '../../themeConfig'
import i18n from '@/libs/i18n'
import profile from '@/router/profile'
import role from '@/auth/role'

Vue.use(VueRouter)

const routes = [
  ...auth,
  ...organization,
  ...customer,
  ...error,
  ...profile,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { access } = to.meta
  const currentUser = JSON.parse(localStorage.getItem('userInfo'))

  if (access) {
    if (!currentUser) {
      localStorage.setItem('previousPath', to.path)

      // not logged in so redirect to login page with the return url
      return next({ name: 'logout' })
    }

    // check if user account is active
    if (!['checkout', 'organization-wizard'].includes(to.name) && currentUser.type === 'Teacher' && dayjs(currentUser.organization.subscriptionEndsAt)
      .diff(dayjs(), 'minutes') < -60) {
      return next({ name: 'checkout' })
    }

    if (to.name !== 'organization-wizard' && currentUser.roles.includes(role.admin) && currentUser.organization && !currentUser.organization.profileComplete) {
      return next({ name: 'organization-wizard' })
    }

    if (to.name === 'organization-wizard' && currentUser.organization && currentUser.organization.profileComplete) {
      return next({ name: 'dashboard-redirect' })
    }

    // check if route is restricted by role
    if (access.length && !access.some(userRole => currentUser.roles.includes(userRole))) {
      // role not authorised so redirect to home page
      // window.toast.notify.warning(
      //   i18n.t('auth.login.errors.access-denied'),
      //   i18n.t('auth.login.errors.access-denied-description'),
      // )
      return next({ name: 'dashboard-redirect' })
    }
  }

  return next()
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { title } = to.meta

  if (!title) {
    document.title = $themeConfig.app.appName
    return next()
  }

  document.title = `${i18n.t(title)} - ${$themeConfig.app.appName}`

  return next()
})

export default router
