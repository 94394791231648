import scriptGroups from '@/http/requests/scripts'

export default {
  fetchScriptGroup: ({ commit }, scriptGroupId) => new Promise((resolve, reject) => {
    commit('SET_SCRIPT_GROUP', {})
    scriptGroups.getScriptGroup(scriptGroupId)
      .then(response => {
        commit('SET_SCRIPT_GROUP', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchScriptGroups: ({ commit }) => new Promise((resolve, reject) => {
    scriptGroups.getScriptGroups()
      .then(response => {
        commit('SET_SCRIPT_GROUPS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addScriptGroup: (_, payload) => new Promise((resolve, reject) => {
    scriptGroups.postScriptGroup(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchScriptGroup: (_, { id, data }) => new Promise((resolve, reject) => {
    scriptGroups.patchScriptGroup(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetScriptGroup: ({ commit }) => new Promise(() => {
    commit('CLEAR_SCRIPT_GROUP')
  }),
}
