import { cloneNested } from '@/helpers/helpers'

const scriptGroupModel = {
  name: null,
  coursebook: null,
}

export default {
  scriptGroup: cloneNested(scriptGroupModel),
  scriptGroupModel: cloneNested(scriptGroupModel),
  scriptGroups: [],
}
