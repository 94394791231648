import axios from '@/libs/axios'

export default {
  getSettlement(settlementId) {
    return axios.get(`/settlements/${settlementId}`)
  },
  getSettlements(params) {
    return axios.get('/settlements', { params })
  },
  postSettlement(payload) {
    return axios.post('/settlements', payload)
  },
  postAddPayment(payload) {
    return axios.post('/settlements/add-payment', payload)
  },
  patchSettlement(settlementId, payload) {
    return axios.patch(`/settlements/${settlementId}`, payload)
  },
}
