import { cloneNested } from '@/helpers/helpers'

const calendarEventModel = {
  id: null,
  calendar: {},
  calendarEventSequence: {},
  ordinalNumber: 1,
  calendarEventCustomers: [],
  service: null,
  title: null,
  startAt: null,
  endAt: null,
  startedAt: null,
  additionalTimeBefore: 0,
  additionalTimeAfter: 0,
  priceGross: null,
  participantLimit: null,
}

export default {
  calendarEvent: cloneNested(calendarEventModel),
  calendarEventModel: cloneNested(calendarEventModel),
  calendarEvents: [],
  selectedCalendars: [],
}
