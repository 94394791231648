import publicPayments from '@/http/requests/public/payments'

export default {
  fetchSettlementsToPay: (_, customerId) => new Promise((resolve, reject) => {
    publicPayments.getSettlementsToPayByCustomerId(customerId)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
