import _ from 'lodash'

export default {
  SET_CUSTOMER(state, payload) {
    state.customer = _.mergeWith(_.cloneDeep(state.customerModel), payload)
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload
  },
  CLEAR_CUSTOMER(state) {
    state.customer = _.cloneDeep(state.customerModel)
  },
  SET_SHOW_ARCHIVED(state, showArchived) {
    state.showArchived = showArchived
  },
  SET_CUSTOMER_CALENDAR_EVENTS(state, payload) {
    state.customerCalendarEvents = payload
  },
  SET_CUSTOMER_BALANCE_HISTORIES(state, payload) {
    state.customerBalanceHistories = payload
  },
  SET_CUSTOMER_STATISTICS(state, payload) {
    state.customerStatistics = { ..._.omitBy(state.customerStatistics, _.isNull), ..._.omitBy(payload, _.isNull) }
  },
  CLEAR_CUSTOMER_STATISTICS(state) {
    state.customerStatistics = _.cloneDeep(state.customerStatisticsModel)
  },
}
