import services from '@/http/requests/services'

export default {
  fetchService: ({ commit }, serviceId) => new Promise((resolve, reject) => {
    commit('SET_SERVICE', {})
    services.getService(serviceId)
      .then(response => {
        commit('SET_SERVICE', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchServices: ({ commit }, params) => new Promise((resolve, reject) => {
    services.getServices(params)
      .then(response => {
        commit('SET_SERVICES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addService: (_, payload) => new Promise((resolve, reject) => {
    services.postService(payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  editService: (_, payload) => new Promise((resolve, reject) => {
    services.putService(payload.serviceId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetService: ({ commit }) => new Promise(() => {
    commit('CLEAR_SERVICE')
    commit('CLEAR_STATISTICS')
  }),
  fetchStatistics: ({ commit }, { serviceId, params }) => new Promise((resolve, reject) => {
    commit('CLEAR_STATISTICS')
    services.getStatistics(serviceId, params)
      .then(response => {
        commit('SET_STATISTICS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
