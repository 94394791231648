import Vue from 'vue'
import { computed } from '@vue/composition-api'
import store from '@/store'

const user = computed(() => store.getters['auth/getUser'])

Vue.directive('acl', {
  inserted(el, binding) {
    // Focus the element
    // console.log(binding.value)
    // console.log(el)

    if (!user?.value?.roles?.length) {
      el.remove()
    }

    if (!user.value.roles.some(role => binding.value.includes(role))) {
      el.remove()
    }
  },
})
