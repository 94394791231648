import calendar from '@/router/organization/calendar'
import customer from '@/router/organization/customer'
import dashboard from '@/router/organization/dashboard'
import meeting from '@/router/organization/meeting'
import script from '@/router/organization/script'
import service from '@/router/organization/service'
import integrations from '@/router/organization/integrations'
import settings from '@/router/organization/settings'
import wizard from '@/router/organization/wizard'
import settlements from '@/router/organization/settlement'

export default [
  ...calendar,
  ...customer,
  ...dashboard,
  ...wizard,
  ...meeting,
  ...script,
  ...service,
  ...integrations,
  ...settings,
  ...settlements,
]
