import moduleCalendarState from './calendar/moduleCalendarState'
import moduleCalendarMutations from './calendar/moduleCalendarMutations'
import moduleCalendarActions from './calendar/moduleCalendarActions'
import moduleCalendarGetters from './calendar/moduleCalendarGetters'
import moduleSettlementState from '@/store/customers/settlement/moduleSettlementState'
import moduleSettlementMutations from '@/store/customers/settlement/moduleSettlementMutations'
import moduleSettlementActions from '@/store/customers/settlement/moduleSettlementActions'
import moduleSettlementGetters from '@/store/customers/settlement/moduleSettlementGetters'
import moduleUserState from '@/store/customers/user/moduleUserState'
import moduleUserMutations from '@/store/customers/user/moduleUserMutations'
import moduleUserActions from '@/store/customers/user/moduleUserActions'
import moduleUserGetters from '@/store/customers/user/moduleUserGetters'

export default {
  namespaced: true,
  state: { ...moduleCalendarState, ...moduleSettlementState, moduleUserState },
  mutations: { ...moduleCalendarMutations, ...moduleSettlementMutations, ...moduleUserMutations },
  actions: { ...moduleCalendarActions, ...moduleSettlementActions, ...moduleUserActions },
  getters: { ...moduleCalendarGetters, ...moduleSettlementGetters, ...moduleUserGetters },
}
