import role from '@/auth/role'

export default [
  {
    path: '/organization/services',
    name: 'organization-services',
    component: () => import('@/views/organization/services/Services'),
    meta: {
      title: 'navigation.services',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
  {
    path: '/organization/services/:serviceId/view',
    name: 'organization-services-view',
    component: () => import('@/views/organization/services/Services'),
    meta: {
      title: 'navigation.services',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
  {
    path: '/organization/services/:serviceId/edit',
    name: 'organization-services-edit',
    component: () => import('@/views/organization/services/Services'),
    meta: {
      title: 'navigation.services',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
]
