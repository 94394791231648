import state from './moduleScriptGroupState'
import mutations from './moduleScriptGroupMutations'
import actions from './moduleScriptGroupActions'
import getters from './moduleScriptGroupGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
