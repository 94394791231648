import _ from 'lodash'

export default {
  SET_SCRIPT(state, payload) {
    state.script = _.mergeWith(_.cloneDeep(state.scriptModel), payload)
  },
  SET_SCRIPTS(state, payload) {
    state.scripts = payload
  },
  CLEAR_SCRIPT(state) {
    state.script = _.cloneDeep(state.scriptModel)
  },
}
