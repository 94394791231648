import role from '@/auth/role'

export default [
  {
    path: '/organization/settings/profile',
    name: 'organization-settings-company-profile',
    component: () => import('@/views/organization/settings/profile/ProfileSettings'),
    meta: {
      title: 'navigation.settings.company-profile',
      access: [role.admin],
      pageTitle: 'navigation.settings.company-profile',
      breadcrumb: [
        {
          text: 'navigation.settings.company-profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/settings/plan-and-billing',
    name: 'organization-settings-plan-and-billing',
    component: () => import('@/views/organization/settings/plan-and-billing/PlanAndBilling.vue'),
    meta: {
      title: 'navigation.settings.plan-and-billing',
      access: [role.admin],
      pageTitle: 'navigation.account-settings',
      breadcrumb: [
        {
          text: 'navigation.settings.plan-and-billing',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/settings/educators',
    name: 'organization-settings-educators-list',
    component: () => import('@/views/organization/settings/educators/List.vue'),
    meta: {
      title: 'navigation.settings.educators',
      access: [role.admin],
      pageTitle: 'navigation.settings.educators',
      breadcrumb: [
        {
          text: 'navigation.settings.educators',
          active: true,
        },
      ],
    },
  },
  {
    path: '/organization/settings/educators/view/:id',
    name: 'organization-settings-educator-view',
    component: () => import('@/views/organization/settings/educators/View/View.vue'),
    meta: {
      title: 'navigation.settings.educators',
      access: [role.admin],
      pageTitle: 'navigation.settings.educator',
      breadcrumb: [
        {
          text: 'navigation.settings.educators',
          to: { name: 'organization-settings-educators-list' },
        },
        {
          text: 'navigation.settings.educator',
          active: true,
        },
      ],
    },
  },
]
