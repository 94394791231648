import role from '@/auth/role'

export default [
  {
    path: '/customer/meeting/list',
    name: 'customer-meeting-list',
    component: () => import('@/views/customer/meeting/List.vue'),
    meta: {
      title: 'navigation.meeting-list',
      pageTitle: 'navigation.meeting-list',
      access: [role.student, role.guardian],
      breadcrumb: [
        {
          text: 'navigation.meeting-list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer/meeting/view/:id',
    name: 'customer-meeting-view',
    component: () => import('@/views/customer/meeting/View.vue'),
    meta: {
      title: 'navigation.meeting',
      access: [role.student, role.guardian],
      // breadcrumb: [
      //   {
      //     text: 'navigation.calendar',
      //     to: { name: 'organization-calendar' },
      //   },
      //   {
      //     text: 'navigation.meeting',
      //     active: true,
      //   },
      // ],
    },
  },
]
