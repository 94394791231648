/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import _ from 'lodash'
import i18n from '@/libs/i18n'
import SettlementStatusDictionary from '@/dictionary/SettlementStatusDictionary'

const cloneNested = obj => _.cloneDeep(obj)
const randomPassword = () => Array(20)
  .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
  // eslint-disable-next-line no-mixed-operators
  .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)])
  .join('')

const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkNested(obj[level], ...rest)
}

const strToDecimal = str => Number(Number(str).toFixed(2))

const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

const parseRequestError = err => {
  if (checkNested(err, 'errors', 'children')) {
    // eslint-disable-next-line guard-for-in
    for (const key in err.errors.children) {
      const elem = err.errors.children[key]
      if (checkNested(elem, 'errors')) {
        elem.errors.forEach(e => {
          window.toast.notify.warning(
            i18n.t('validations.messages.Validation Failed'),
            i18n.t(`validations.messages.${e}`),
          )
        })
      }
    }
  } else if (checkNested(err, 'message')) {
    if (err.message === 'Validation Failed' && checkNested(err, 'errors', 'errors')) {
      err.errors.errors.forEach(error => {
        window.toast.notify.warning(
          i18n.t('validations.messages.Validation Failed'),
          i18n.t(`validations.messages.${error}`),
        )
      })
    } else {
      window.toast.notify.danger(i18n.t(err.message))
    }
    // console.error(err)
  }
}
const empty = data => {
  if (typeof (data) === 'number' || typeof (data) === 'boolean') {
    return false
  }
  if (typeof (data) === 'undefined' || data === null) {
    return true
  }
  if (typeof (data.length) !== 'undefined') {
    return data.length === 0
  }
  let count = 0
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      count += 1
    }
  }
  return count === 0
}

const sort = data => _.orderBy(
  data,
  [
    item => item.customer.lastName,
    item => item.customer.firstName,
  ],
  'asc',
)

const safePush = (arr, value) => {
  let newArr = arr
  if (typeof arr === 'undefined') {
    newArr = []
  }

  newArr.push(value)

  return newArr
}

const getIntegers = number => Math.floor(number)
const getDecimals = number => Math.round((Math.abs(number) % 1) * 100)
  .toLocaleString('pl-PL', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })

const getColorVariantByPaymentStatus = status => {
  switch (status) {
    case SettlementStatusDictionary.ToPay:
      return 'warning'
    case SettlementStatusDictionary.OverduePayment:
      return 'danger'
    case SettlementStatusDictionary.Paid:
      return 'success'
    case SettlementStatusDictionary.Scheduled:
      return 'secondary'
    default:
      return 'info'
  }
}

const isUrl = str => {
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/
  return urlRegex.test(str)
}

const fixAndReturnUrl = urlString => {
  let url = urlString
  if (!/^https?:\/\//i.test(urlString)) {
    url = `http://${url}`
  }

  // Create and return URL object
  try {
    return new URL(url)
  } catch (e) {
    return null // Return null if the URL is invalid
  }
}

export {
  cloneNested,
  randomPassword,
  parseRequestError,
  checkNested,
  empty,
  strToDecimal,
  capitalizeFirstLetter,
  sort,
  safePush,
  getIntegers,
  getDecimals,
  getColorVariantByPaymentStatus,
  isUrl,
  fixAndReturnUrl,
}
