import state from './calendarEditingState'
import mutations from './calendarEditingMutations'
import actions from './calendarEditingActions'
import getters from './calendarEditingGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
