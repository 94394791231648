import _ from 'lodash'

export default {
  SET_SCRIPT_GROUP(state, payload) {
    state.scriptGroup = _.mergeWith(_.cloneDeep(state.scriptGroupModel), payload)
  },
  SET_SCRIPT_GROUPS(state, payload) {
    state.scriptGroups = payload
  },
  CLEAR_SCRIPT_GROUP(state) {
    state.scriptGroup = _.cloneDeep(state.scriptGroupModel)
  },
}
