import role from '@/auth/role'

export default [
  {
    path: '/',
    name: 'dashboard-redirect',
    redirect: () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo')) || {}

      if (typeof userInfo.roles === 'undefined') {
        return { name: 'logout' }
      }

      if (userInfo.roles.includes(role.admin) && userInfo.organization && !userInfo.organization.profileComplete) {
        return { name: 'organization-wizard' }
      }

      if (userInfo.roles.includes(role.educator)) {
        return { name: 'organization-dashboard' }
      }

      if (userInfo.roles.includes(role.student) || userInfo.roles.includes(role.guardian)) {
        return { name: 'customer-dashboard' }
      }

      return { name: 'logout' }
    },
    meta: {},
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]
