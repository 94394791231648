import state from './moduleSettlementState'
import mutations from './moduleSettlementMutations'
import actions from './moduleSettlementActions'
import getters from './moduleSettlementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
