import { cloneNested } from '@/helpers/helpers'

const scriptModel = {
  subject: null,
  coursebookNotes: null,
  scriptsCount: 0,
  scriptGroup: {},
}

export default {
  script: cloneNested(scriptModel),
  scriptModel: cloneNested(scriptModel),
  scripts: [],
}
