import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from 'vee-validate/dist/locale/pl.json'
import en from 'vee-validate/dist/locale/en.json'
import _ from 'lodash'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {
    en: {
      validations: en,
    },
    pl: {
      validations: pl,
    },
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = _.merge(messages[locale], locales(key))
    }
  })

  return messages
}

const pluralizationRules = {
  /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
  pl: (choice, choicesLength) => {
    // this === VueI18n instance, so the locale property also exists here

    if (typeof choice !== 'number') {
      throw new Error('Pluralization choice is not an number')
    }

    if (choice === 0 || choice === 1) {
      return choice
    }

    const teen = choice > 10 && choice < 20
    const endsWithTwoToFour = [2, 3, 4].includes(choice % 10)

    if (choicesLength < 4) {
      return (!teen && endsWithTwoToFour) ? 2 : 3
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
      return 2
    }

    return (choicesLength < 4) ? 2 : 3
  },
}

export default new VueI18n({
  pluralizationRules,
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: loadLocaleMessages(),
})
