export default {
  isProcessing: state => state.processing,
  isFormVisible: state => state.formVisible,
  updateFutureEvents: state => state.updateFutureEvents,
  cancelConflictingEvents: state => state.cancelConflictingEvents,
  overrideServiceDefaults: state => state.overrideServiceDefaults,
  loadingServiceDetails: state => state.loadingServiceDetails,
  eventId: state => state.eventId,
  event: state => state.event,
  eventModel: state => state.eventModel,
  collisions: state => state.collisions,
}
