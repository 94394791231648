import { cloneNested } from '@/helpers/helpers'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

const calendarEventModel = {
  id: null,
  type: CalendarEventTypeDictionary.Appointment,
  calendar: null,
  calendarObject: {},
  calendarEventSequence: {},
  ordinalNumber: 1,
  calendarEventCustomers: {},
  calendarEventCustomerObjects: {},
  service: null,
  title: null,
  startAt: null,
  endAt: null,
  additionalTimeBefore: 0,
  additionalTimeAfter: 0,
  priceGross: null,
  participantLimit: null,
}

export default {
  event: cloneNested(calendarEventModel),
  eventModel: cloneNested(calendarEventModel),
  processing: false,
  formVisible: false,
  updateFutureEvents: false,
  cancelConflictingEvents: false,
  overrideServiceDefaults: false,
  loadingServiceDetails: false,
  eventId: null,
  collisions: [],
}
