import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import attachment from './attachment'
import verticalMenu from './vertical-menu'
import auth from './auth'
import customer from './customer'
import service from './service'
import calendar from './calendar'
import settlement from '@/store/settlement'
import script from '@/store/script'
import scriptGroup from '@/store/script-group'
import calendarEventEditing from '@/store/calendar-event-editing'
import customers from '@/store/customers'
import organization from '@/store/organization'
import publicStore from '@/store/publicStore'
import user from '@/store/user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    attachment,
    verticalMenu,
    auth,
    customer,
    service,
    calendar,
    settlement,
    script,
    scriptGroup,
    calendarEventEditing,
    organization,
    publicStore,
    user,

    customers,
  },
  strict: process.env.DEV,
})
