import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'

// import 'dayjs/locale/pl'
import 'dayjs/locale/pl'
import { cloneNested } from '@/helpers/helpers'

dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localeData)
dayjs.extend(isToday)
dayjs.extend(localizedFormat)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('pl')

const customerModel = {
  firstName: '',
  lastName: '',
  balance: 0,
  discountPercent: 0,
  settlementTime: 'AtTheEndOfMonth',
  paymentDeadline: 7,
  address: {},
  invoiceDetails: {},
  timezone: dayjs.tz.guess(),
  canCancelReservations: true,
  freeCancellationMinHours: 0,
}

const customerStatisticsModel = {
  totalMeetings: 0,
  customerBalance: 0,
  plannedMeetings: 0,
  pastMeetings: 0,
  canceledMeetingsByCustomer: 0,
  canceledMeetingsByTeacher: 0,
  customerAbsenceMeetings: 0,
  incomeToPay: 0,
  incomePaid: 0,
}

export default {
  customer: cloneNested(customerModel),
  customerModel: cloneNested(customerModel),
  customers: [],
  customerCalendarEvents: [],
  customerBalanceHistories: [],
  customerStatistics: cloneNested(customerStatisticsModel),
  customerStatisticsModel: cloneNested(customerStatisticsModel),
  showArchived: false,
}
