import state from './moduleScriptState'
import mutations from './moduleScriptMutations'
import actions from './moduleScriptActions'
import getters from './moduleScriptGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
