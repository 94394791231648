import { cloneNested } from '@/helpers/helpers'

export default {
  SET_PROCESSING(state, value) {
    state.processing = value
  },
  SET_FORM_VISIBLE(state, value) {
    state.formVisible = value
  },
  SET_UPDATE_FUTURE_EVENTS(state, value) {
    state.updateFutureEvents = value
  },
  SET_CANCEL_CONFLICTING_EVENTS(state, value) {
    state.cancelConflictingEvents = value
  },
  SET_OVERRIDE_SERVICE_DEFAULTS(state, value) {
    state.overrideServiceDefaults = value
  },
  SET_LOADING_SERVICE_DETAILS(state, value) {
    state.loadingServiceDetails = value
  },
  SET_EVENT(state, value) {
    state.event = value
  },
  SET_EVENT_ID(state, value) {
    state.eventId = value
  },
  CLEAR_EVENT(state) {
    state.event = cloneNested(state.eventModel)
  },
  SET_COLLISIONS(state, value) {
    state.collisions = value
  },
}
