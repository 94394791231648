import _ from 'lodash'

export default {
  SET_CALENDAR_EVENT(state, payload) {
    state.calendarEvent = _.mergeWith(_.cloneDeep(state.calendarEventModel), payload)
  },
  SET_CALENDAR_EVENTS(state, payload) {
    state.calendarEvents = payload
  },
  CLEAR_CALENDAR_EVENT(state) {
    state.calendarEvent = _.cloneDeep(state.calendarEventModel)
  },
  SET_SELECTED_CALENDARS(state, payload) {
    state.selectedCalendars = payload
  },
}
