import _ from 'lodash'

export default {
  SET_SERVICE(state, payload) {
    state.service = _.mergeWith(_.cloneDeep(state.serviceModel), payload)
  },
  SET_SERVICES(state, payload) {
    state.services = payload
  },
  CLEAR_SERVICE(state) {
    state.service = _.cloneDeep(state.serviceModel)
  },
  SET_STATISTICS(state, payload) {
    state.statistics = { ..._.omitBy(state.statisticsModel, _.isNull), ..._.omitBy(payload, _.isNull) }
  },
  CLEAR_STATISTICS(state) {
    state.statistics = _.cloneDeep(state.statisticsModel)
  },
  SET_SHOW_ARCHIVED(state, showArchived) {
    state.showArchived = showArchived
  },
}
