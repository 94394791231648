import axios from '@/libs/axios'

export default {
  getCustomerSettlements() {
    return axios.get('/user/settlement')
  },
  getPaymentIntegrationsForOrganization(organizationId) {
    return axios.get(`/public/organizations/${organizationId}/payment-integrations`)
  },
  getPaymentIntegrationsForCustomer(customerId) {
    return axios.get(`/public/customers/${customerId}/payment-integrations`)
  },
  getPaymentStatus(paymentStatusId) {
    return axios.get(`/payments/${paymentStatusId}/status`)
  },
}
