import attachments from '@/http/requests/attachments'

export default {
  fetchAttachment: ({ commit }, attachmentId) => new Promise((resolve, reject) => {
    commit('SET_ATTACHMENT', {})
    attachments.getAttachment(attachmentId)
      .then(response => {
        commit('SET_ATTACHMENT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAttachments: ({ commit }) => new Promise((resolve, reject) => {
    attachments.getAttachments()
      .then(response => {
        commit('SET_ATTACHMENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchAttachmentsByResource: (_, { resource, resourceId }) => new Promise((resolve, reject) => {
    attachments.getAttachmentsByResource(resource, resourceId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAttachment: (_, {
    type, resource, resourceId, payload,
  }) => new Promise((resolve, reject) => {
    attachments.postAttachment(type, resource, resourceId, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchAttachment: (_, {
    type, resource, payload,
  }) => new Promise((resolve, reject) => {
    attachments.patchAttachment(payload.id, type, resource, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetAttachment: ({ commit }) => new Promise(() => {
    commit('CLEAR_ATTACHMENT')
  }),
  uploadFile: (_, { file, attachmentOrganizationId, progressCallback }) => new Promise((resolve, reject) => {
    attachments.postUploadFile(file, attachmentOrganizationId, progressCallback)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  downloadAttachmentFile: (_, attachmentId) => new Promise((resolve, reject) => {
    attachments.getDownloadAttachmentFile(attachmentId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteAttachment: (_, id) => new Promise((resolve, reject) => {
    attachments.deleteAttachment(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
