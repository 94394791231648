import calendarEvents from '@/http/requests/calendars/event'
import { sort } from '@/helpers/helpers'

export default {
  fetchCalendarEvent: ({ commit }, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEvent(calendarEventId)
      .then(response => {
        commit('CLEAR_CALENDAR_EVENT', {})

        const { data } = response
        data.calendarEventCustomerObjects = Object.values(sort(data.calendarEventCustomers))
        data.calendarEventCustomers = data.calendarEventCustomerObjects.map(calendarEventCustomer => calendarEventCustomer.customer.id)

        commit('SET_CALENDAR_EVENT', data)
        resolve(data)
      })
      .catch(error => {
        commit('CLEAR_CALENDAR_EVENT', {})
        reject(error)
      })
  }),

  fetchCalendarEvents: ({ commit }, payload) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEvents(payload)
      .then(response => {
        // const
        response.data.forEach((calendarEvent, key) => {
          // eslint-disable-next-line no-param-reassign
          response.data[key].calendarEventCustomerObjects = Object.values(sort(calendarEvent.calendarEventCustomers))
          // eslint-disable-next-line no-param-reassign
          response.data[key].calendarEventCustomers = response.data[key].calendarEventCustomerObjects.map(calendarEventCustomer => calendarEventCustomer.customer.id)
        })

        commit('SET_CALENDAR_EVENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventsByCalendarId: ({ commit }, payload) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEventsByCalendarId(
      payload.calendarId,
      {
        from: payload.from,
        to: payload.to,
      },
    )
      .then(response => {
        commit('SET_CALENDAR_EVENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  patchCalendarEvent: (_, payload) => new Promise((resolve, reject) => {
    calendarEvents.patchCalendarEvent(payload.calendarEventId, payload.data, payload.params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  addCalendarEvent: (_, payload) => new Promise((resolve, reject) => {
    calendarEvents.postCalendarEvent(payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  getCalendarEventViewNavigation: (_, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEventViewNavigation(calendarEventId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  cancelCalendarEvent: (_, { calendarEventId, params }) => new Promise((resolve, reject) => {
    calendarEvents.cancelCalendarEvent(calendarEventId, params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventsStatistics: (_, params) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarStatistics('events', params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventCustomersStatistics: (_, params) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarStatistics('event-customers', params)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  resetCalendarEvent: ({ commit }) => new Promise(() => {
    commit('CLEAR_CALENDAR_EVENT')
  }),

  patchCalendarEventCustomer: (_, { calendarEventCustomerId, data }) => new Promise((resolve, reject) => {
    calendarEvents.patchCalendarEventCustomer(calendarEventCustomerId, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  setCalendarEventCustomerPresence: (_, { calendarEventCustomerId, presence }) => new Promise((resolve, reject) => {
    calendarEvents.setCalendarEventCustomerPresence(calendarEventCustomerId, presence)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventSummary: (_, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEventSummary(calendarEventId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  confirmCalendarEventSummary: (_, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.confirmCalendarEventSummary(calendarEventId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventPreviousExercises: (_, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEventPreviousExercises(calendarEventId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  addExerciseResult: (_, { calendarEventCustomerId, exerciseId }) => new Promise((resolve, reject) => {
    calendarEvents.addExerciseResult(calendarEventCustomerId, exerciseId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  removeExerciseResult: (_, { calendarEventCustomerId, exerciseId }) => new Promise((resolve, reject) => {
    calendarEvents.removeExerciseResult(calendarEventCustomerId, exerciseId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  patchExerciseResult: (_, { id, data }) => new Promise((resolve, reject) => {
    calendarEvents.patchExerciseResult(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventPreviousKnowledgeChecks: (_, calendarEventId) => new Promise((resolve, reject) => {
    calendarEvents.getCalendarEventPreviousKnowledgeChecks(calendarEventId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  addKnowledgeCheckRating: (_, { calendarEventCustomerId, knowledgeCheckId }) => new Promise((resolve, reject) => {
    calendarEvents.addKnowledgeCheckRating(calendarEventCustomerId, knowledgeCheckId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  removeKnowledgeCheckRating: (_, { calendarEventCustomerId, knowledgeCheckId }) => new Promise((resolve, reject) => {
    calendarEvents.removeKnowledgeCheckRating(calendarEventCustomerId, knowledgeCheckId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  patchKnowledgeCheckRating: (_, { id, data }) => new Promise((resolve, reject) => {
    calendarEvents.patchKnowledgeCheckRating(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
