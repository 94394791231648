// axios
import axios from 'axios'
import qs from 'qs'

const baseURL = process.env.VUE_APP_API_URL

if (localStorage.getItem('tokens')) {
  axios.defaults.headers.common.Authorization = `Bearer ${JSON.parse(localStorage.getItem('tokens')).token}`
}

const axiosIns = axios.create({
  baseURL,
  paramsSerializer: p => qs.stringify(p, { arrayFormat: 'brackets' }),
})

export default axiosIns
