import role from '@/auth/role'

export default [
  {
    path: '/customer/contacts',
    name: 'customer-contacts',
    component: () => import('@/views/customer/contacts/Contacts.vue'),
    meta: {
      title: 'navigation.contacts',
      pageTitle: 'navigation.contacts',
      access: [role.student, role.guardian],
      breadcrumb: [
        {
          text: 'navigation.contacts',
          active: true,
        },
      ],
    },
  },
]
