import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'

import VueGtag from 'vue-gtag'
import VueTelInput from 'vue-tel-input'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

import 'vue-tel-input/dist/vue-tel-input.css'
import '@core/scss/vue/libs/vue-select.scss'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// vue flat-pickr
import '@core/scss/vue/libs/vue-flatpicker.scss'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/composition-api'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import {
  transformDatetimeToHumanReadablePretty,
  transformDateToHumanReadablePretty,
  transformTimeToHumanReadablePretty,
} from '@/helpers/dateTimeHelper'

// Vue g-tag
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG },
}, router)

// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Vue Tel Input
Vue.use(VueTelInput)

Vue.filter('pretty', value => JSON.stringify(value, null, 2))
Vue.filter('transformDateToHumanReadablePretty', transformDateToHumanReadablePretty)
Vue.filter('transformDatetimeToHumanReadablePretty', transformDatetimeToHumanReadablePretty)
Vue.filter('transformTimeToHumanReadablePretty', transformTimeToHumanReadablePretty)

// import core styles
require('@core/scss/core.scss')
require('./assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
