import role from '@/auth/role'

export default [
  {
    path: '/organization/meeting/view/:id',
    name: 'organization-meeting-view',
    component: () => import('@/views/organization/meeting/View.vue'),
    meta: {
      title: 'navigation.meeting',
      access: [role.educator],
      breadcrumb: [
        {
          text: 'navigation.calendar',
          to: { name: 'organization-calendar' },
        },
        {
          text: 'navigation.meeting',
          active: true,
        },
      ],
    },
  },
]
