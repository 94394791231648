import login from '@/http/requests/auth/login/index.js'
import checkout from '@/http/requests/checkout'
import usetiful from '@/libs/usetiful'

export default {
  login: ({ commit }, payload) => new Promise((resolve, reject) => {
    login.login(payload)
      .then(response => {
        commit('SET_TOKEN', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  logout: ({ commit }) => new Promise(resolve => {
    commit('REMOVE_USER_TOKEN')
    commit('REMOVE_USER_INFO')
    commit('SET_FETCHING_USER_INFO', false)

    caches.keys()
      .then(names => {
        for (let i = 0; i < names.length; i += 1) {
          const name = names[i]
          caches.delete(name)
        }
      })
    resolve(true)
  }),
  register: (_, payload) => new Promise((resolve, reject) => {
    login.register(payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  registerInOrganization: (_, payload) => new Promise((resolve, reject) => {
    login.registerInOrganization(payload.organizationId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  confirmRegistration: (_, payload) => new Promise((resolve, reject) => {
    login.confirmRegistration(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  forgotPassword: (_, email) => new Promise((resolve, reject) => {
    login.forgotPassword({ email })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  changePassword: (_, payload) => new Promise((resolve, reject) => {
    login.resetPassword(payload.userId, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchUserInfo: ({ commit, state }) => new Promise((resolve, reject) => {
    if (!state.fetchingUserInfo) {
      commit('SET_FETCHING_USER_INFO', true)
      login.userInfo()
        .then(res => {
          commit('SET_USER_INFO', res.data)
          usetiful.init()

          resolve(res)
        })
        .catch(err => reject(err))
        .finally(() => {
          commit('SET_FETCHING_USER_INFO', false)
        })
    }
  }),
  fetchAccessTokenByRefreshToken: ({ commit }) => new Promise((resolve, reject) => {
    login.refreshToken()
      .then(response => {
        commit('SET_TOKEN', response.data)
        resolve(response)
      })
      .catch(err => reject(err))
  }),
  fetchNotifications: ({ commit }) => new Promise((resolve, reject) => {
    try {
      login.userNotifications()
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data)
          resolve(response)
        })
    } catch (error) {
      reject(error)
    }
  }),
  markNotificationAsRead: (_, id) => new Promise((resolve, reject) => {
    login.markNotificationAsRead(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  prepareRedirectUrl: (_, payload) => new Promise((resolve, reject) => {
    checkout.createCheckoutSession(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
