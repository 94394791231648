import calendars from '@/http/requests/customers/calendars'
import { sort } from '@/helpers/helpers'

export default {
  fetchCustomerCalendarEvent: ({ commit }, calendarEventId) => new Promise((resolve, reject) => {
    calendars.getCustomerCalendarEvent(calendarEventId)
      .then(response => {
        commit('CLEAR_CUSTOMER_CALENDAR_EVENT', {})

        const { data } = response
        data.calendarEventCustomerObjects = Object.values(sort(data.calendarEventCustomers))
        data.calendarEventCustomers = data.calendarEventCustomerObjects.map(calendarEventCustomer => calendarEventCustomer.customer.id)

        commit('SET_CUSTOMER_CALENDAR_EVENT', data)
        resolve(data)
      })
      .catch(error => {
        commit('CLEAR_CUSTOMER_CALENDAR_EVENT', {})
        reject(error)
      })
  }),

  fetchCustomerCalendarEvents: ({ commit }, payload) => new Promise((resolve, reject) => {
    commit('CLEAR_CUSTOMER_CALENDAR_EVENTS')
    calendars.getCustomerCalendarEvents(payload)
      .then(response => {
        commit('SET_CUSTOMER_CALENDAR_EVENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  patchCustomerExerciseResult: (_, { id, data }) => new Promise((resolve, reject) => {
    calendars.patchCustomerExerciseResult(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchCalendarEventToPay: (_, customerId) => new Promise((resolve, reject) => {
    calendars.getCalendarEventToPay(customerId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  preparePaymentUrl: (_, { integrationId, payload }) => new Promise((resolve, reject) => {
    calendars.getPaymentUrl(integrationId, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
