const baseInitializer = (w, d, s) => {
  const a = d.getElementsByTagName('head')[0]
  const r = d.createElement('script')
  r.async = 1
  r.src = s
  r.setAttribute('id', 'usetifulScript')
  r.dataset.token = process.env.VUE_APP_USETIFUL_ID
  a.appendChild(r)
}

// Vue usetiful.com
const init = () => {
  if (window.usetifulInitialized) {
    return
  }

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!userInfo || !userInfo.id) {
    return
  }

  if (process.env.VUE_APP_USETIFUL_ID) {
    // personalize usetiful
    window.usetifulTags = {
      userId: `${process.env.VUE_APP_ENVIRONMENT}-${userInfo.id}`,
      language: 'pl',
      role: userInfo.type,
      firstName: userInfo.firstName,
    }

    baseInitializer(window, document, 'https://www.usetiful.com/dist/usetiful.js')

    window.usetifulInitialized = true
  }
}
export default {
  init,
}
