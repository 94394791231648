import customers from '@/http/requests/customers/customer'

export default {
  fetchCustomer: ({ commit }, customerId) => new Promise((resolve, reject) => {
    customers.getCustomer(customerId)
      .then(response => {
        commit('SET_CUSTOMER', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchCustomers: ({ commit }, params) => new Promise((resolve, reject) => {
    customers.getCustomers(params)
      .then(response => {
        commit('SET_CUSTOMERS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchCustomer: (_, payload) => new Promise((resolve, reject) => {
    customers.patchCustomer(payload.customerId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addCustomer: (_, payload) => new Promise((resolve, reject) => {
    customers.postCustomer(payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  editCustomer: (_, payload) => new Promise((resolve, reject) => {
    customers.putCustomer(payload.customerId, payload.data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetCustomer: ({ commit }) => new Promise(() => {
    commit('CLEAR_CUSTOMER')
    commit('SET_CUSTOMER_CALENDAR_EVENTS', [])
  }),
  fetchCustomerCalendarEvents: ({ commit }, { customerId, params }) => new Promise((resolve, reject) => {
    commit('SET_CUSTOMER_CALENDAR_EVENTS', [])
    customers.getCustomerCalendarEvents(customerId, params)
      .then(response => {
        commit('SET_CUSTOMER_CALENDAR_EVENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchCustomerBalanceHistories: ({ commit }, { customerId, params }) => new Promise((resolve, reject) => {
    commit('SET_CUSTOMER_BALANCE_HISTORIES', [])
    customers.getCustomerBalanceHistories(customerId, params)
      .then(response => {
        commit('SET_CUSTOMER_BALANCE_HISTORIES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchCustomerStatistics: ({ commit }, { customerId, params }) => new Promise((resolve, reject) => {
    commit('CLEAR_CUSTOMER_STATISTICS')
    customers.getCustomerStatistics(customerId, params)
      .then(response => {
        commit('SET_CUSTOMER_STATISTICS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
