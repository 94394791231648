import role from '@/auth/role'

export default [
  {
    path: '/organization/integrations',
    name: 'organization-integrations',
    component: () => import('@/views/organization/integrations/List.vue'),
    meta: {
      title: 'navigation.integrations',
      access: [role.admin],
      pageTitle: 'navigation.integrations',
      breadcrumb: [
        {
          text: 'navigation.list',
          active: true,
        },
      ],
    },
  },
]
