export default [
  {
    path: '/profile/:profileId',
    name: 'profile',
    component: () => import('@/views/profile/main/Main.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/profile/:profileId/reservation/:serviceId?/:teacherId?',
    name: 'profile-reservation',
    component: () => import('@/views/profile/reservation/Reservation.vue'),
    meta: {
      // layout: 'full',
    },
  },
]
