import role from '@/auth/role'

export default [
  {
    path: '/organization/customers',
    name: 'organization-customers',
    component: () => import('@/views/organization/customers/Customers'),
    meta: {
      title: 'navigation.customers',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
  {
    path: '/organization/customers/:customerId/view',
    name: 'organization-customers-view',
    component: () => import('@/views/organization/customers/Customers'),
    meta: {
      title: 'navigation.customers',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
  {
    path: '/organization/customers/:customerId/edit',
    name: 'organization-customers-edit',
    component: () => import('@/views/organization/customers/Customers'),
    meta: {
      title: 'navigation.customers',
      access: [role.educator],
      contentRenderer: 'sidebar-left',
      contentClass: 'customers-application',
    },
  },
]
