import state from './moduleOrganizationState'
import mutations from './moduleOrganizationMutations'
import actions from './moduleOrganizationActions'
import getters from './moduleOrganizationGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
