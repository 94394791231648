import { cloneNested } from '@/helpers/helpers'

const serviceModel = {
  name: '',
  description: '',
  shortDescription: '',
  priceGross: 0,
  taxPercent: 23,
  duration: 0,
  additionalTimeBefore: 0,
  additionalTimeAfter: 0,
  selfReservationType: 'unavailable',
  serviceLocation: [
    'online',
  ],
  color: '#000000',
  reservationInterval: 30,
  participantLimit: 1,
  serviceTimeFrom: null,
  serviceTimeTo: null,
}

const statisticsModel = {
  totalEvents: 0,
  customerBalance: 0,
  plannedEvents: 0,
  pastEvents: 0,
  incomeToPay: 0,
  incomePaid: 0,
}

export default {
  service: cloneNested(serviceModel),
  serviceModel: cloneNested(serviceModel),
  services: [],
  statistics: cloneNested(statisticsModel),
  statisticsModel: cloneNested(statisticsModel),
  showArchived: false,
}
