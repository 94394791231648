import auth from '@/http/requests/auth/login/index.js'
import organization from '@/http/requests/organization'

export default {
  getOrganizationUsers: ({ commit }) => new Promise((resolve, reject) => {
    auth.organizationUsers()
      .then(response => {
        commit('SET_ORGANIZATION_USERS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getOrganizationUsersPaginated: (_, payload) => new Promise((resolve, reject) => {
    organization.getUsersPaginated(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getOrganizationUser: (_, payload) => new Promise((resolve, reject) => {
    organization.getUserById(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addOrganizationUser: (_, payload) => new Promise((resolve, reject) => {
    organization.addUser(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateOrganizationUser: (_, payload) => new Promise((resolve, reject) => {
    organization.patchUser(payload.id, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
