import dashboard from '@/router/customer/dashboard'
import meeting from '@/router/customer/meeting'
import settlements from '@/router/customer/settlements'
import contacts from '@/router/customer/contacts'

export default [
  ...dashboard,
  ...meeting,
  ...settlements,
  ...contacts,
]
