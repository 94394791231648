import _ from 'lodash'

export default {
  SET_CUSTOMER_CALENDAR_EVENT(state, payload) {
    state.customerCalendarEvent = _.mergeWith(_.cloneDeep(state.customerCalendarEventModel), payload)
  },
  SET_CUSTOMER_CALENDAR_EVENTS(state, payload) {
    state.customerCalendarEvents = payload
  },
  CLEAR_CUSTOMER_CALENDAR_EVENTS(state) {
    state.customerCalendarEvents = []
  },
  CLEAR_CUSTOMER_CALENDAR_EVENT(state) {
    state.customerCalendarEvent = _.cloneDeep(state.customerCalendarEventModel)
  },
}
