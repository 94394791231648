import axios from '@/libs/axios'

export default {
  getCustomerUserDocumentsToAccept() {
    return axios.get('/user/documents/to-accept')
  },
  acceptCustomerDocument(documentId) {
    return axios.post(`/user/documents/${documentId}/accept`)
  },
  declineCustomerDocument(documentId) {
    return axios.post(`/user/documents/${documentId}/decline`)
  },
}
