/*= ========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

import axios from '@/libs/axios'

export default {
  SET_TOKEN(state, payload) {
    localStorage.setItem('tokens', JSON.stringify(payload))
    axios.defaults.headers.common.Authorization = `Bearer ${payload.token}`
  },
  SET_USER_INFO(state, payload) {
    localStorage.setItem('userInfo', JSON.stringify(payload))
    state.activeUser = payload
  },
  REMOVE_USER_TOKEN() {
    localStorage.removeItem('tokens')
    delete axios.defaults.headers.common.Authorization
  },
  REMOVE_USER_INFO() {
    localStorage.removeItem('userInfo')
  },
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload
  },
  SET_FETCHING_USER_INFO(state, isFetching) {
    state.fetchingUserInfo = isFetching
  },
  ADD_NOTIFICATION(state, payload) {
    state.notifications.unshift(payload)
  },
  MARK_NOTIFICATION_AS_READ(state, id) {
    state.notifications.forEach((notification, key) => {
      if (notification.id === id) {
        state.notifications[key].unreaded = false
      }
    })
  },
}
