import role from '@/auth/role'

export default [
  {
    path: '/settlements',
    name: 'settlements-list',
    component: () => import('@/views/organization/settlements/List.vue'),
    meta: {
      title: 'navigation.settlements',
      access: [role.admin],
      pageTitle: 'navigation.settlements',
      breadcrumb: [
        {
          text: 'navigation.settlements',
          active: true,
        },
      ],
    },
  },
]
