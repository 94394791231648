import scripts from '@/http/requests/scripts'

export default {
  fetchScript: ({ commit }, scriptId) => new Promise((resolve, reject) => {
    scripts.getScript(scriptId)
      .then(response => {
        commit('SET_SCRIPT', response.data)
        resolve(response)
      })
      .catch(error => {
        commit('SET_SCRIPT', {})
        reject(error)
      })
  }),
  fetchScripts: ({ commit }) => new Promise((resolve, reject) => {
    scripts.getScripts()
      .then(response => {
        commit('SET_SCRIPTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  updateScriptsOrder: (_, scriptsList) => new Promise((resolve, reject) => {
    scripts.updateScriptsOrder(scriptsList)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addScript: (_, payload) => new Promise((resolve, reject) => {
    scripts.postScript(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchScript: (_, { id, data }) => new Promise((resolve, reject) => {
    scripts.patchScript(id, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetScript: ({ commit }) => new Promise(() => {
    commit('CLEAR_SCRIPT')
  }),
  fetchKnowledgeChecks: (_, { scriptId }) => new Promise((resolve, reject) => {
    scripts.getKnowledgeChecks(scriptId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchKnowledgeChecks: (_, { scriptId, data }) => new Promise((resolve, reject) => {
    scripts.patchKnowledgeChecks(scriptId, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getKnowledgeCheckStatistics: (_, knowledgeCheckId) => new Promise((resolve, reject) => {
    scripts.getKnowledgeCheckStatistics(knowledgeCheckId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),

  fetchExercises: (_, { scriptId }) => new Promise((resolve, reject) => {
    scripts.getExercises(scriptId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchExercises: (_, { scriptId, data }) => new Promise((resolve, reject) => {
    scripts.patchExercises(scriptId, data)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getExerciseStatistics: (_, exerciseId) => new Promise((resolve, reject) => {
    scripts.getExerciseStatistics(exerciseId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
