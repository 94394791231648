import userRequests from '@/http/requests/user'

export default {
  changeMyPassword: (_, payload) => new Promise((resolve, reject) => {
    userRequests.updateMyPassword(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
