import axios from '@/libs/axios'

export default {
  getCustomerCalendarEvent(calendarEventId) {
    return axios.get(`/customers/calendars/appointments/${calendarEventId}`)
  },
  getCustomerCalendarEvents(payload) {
    return axios.get('/customers/calendars/appointments', payload)
  },
  patchCustomerExerciseResult(exerciseResultId, payload, params) {
    return axios.patch(`/customers/scripts/exercises/results/${exerciseResultId}`, payload, { params })
  },
  getCalendarEventToPay(customerId) {
    return axios.get(`/customers/${customerId}/calendars/events-to-pay`)
  },
  getPaymentUrl(integrationId, payload) {
    return axios.post(`/integrations/${integrationId}/payments/new`, payload)
  },
}
