import role from '@/auth/role'

export default [
  {
    path: '/customer/dashboard',
    name: 'customer-dashboard',
    component: () => import('@/views/customer/dashboard/Dashboard.vue'),
    meta: {
      title: 'navigation.dashboard',
      access: [role.student, role.guardian],
    },
  },
]
