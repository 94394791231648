import settlements from '@/http/requests/settlements'

export default {
  fetchSettlement: ({ commit }, settlementId) => new Promise((resolve, reject) => {
    commit('SET_SETTLEMENT', {})
    settlements.getSettlement(settlementId)
      .then(response => {
        commit('SET_SETTLEMENT', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  fetchSettlements: ({ commit }, payload) => new Promise((resolve, reject) => {
    settlements.getSettlements(payload.params)
      .then(response => {
        commit('SET_SETTLEMENTS', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addSettlement: (_, payload) => new Promise((resolve, reject) => {
    settlements.postSettlement(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addPayment: (_, payload) => new Promise((resolve, reject) => {
    settlements.postAddPayment(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  patchSettlement: (_, payload) => new Promise((resolve, reject) => {
    settlements.patchSettlement(payload.settlementId, payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  resetSettlement: ({ commit }) => new Promise(() => {
    commit('CLEAR_SETTLEMENT')
  }),
}
