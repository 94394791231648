import { cloneNested } from '@/helpers/helpers'

const settlementModel = {
  status: null,
  amount: null,
  method: null,
  settlementDate: null,
  comment: null,
}

export default {
  settlement: cloneNested(settlementModel),
  settlementModel: cloneNested(settlementModel),
  settlements: [],
}
