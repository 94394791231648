import role from '@/auth/role'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/auth/Logout.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/auth/Checkout.vue'),
    meta: {
      title: 'checkout.select-subscription',
      pageTitle: 'checkout.select-subscription',
      access: [role.admin, role.educator],
      breadcrumb: [
        {
          text: 'checkout.select-subscription',
          active: true,
        },
      ],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register/:type?',
    name: 'register',
    component: () => import('@/views/auth/Register'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/register/:userId/confirm/:confirmationCode',
    name: 'register-confirm',
    component: () => import('@/views/auth/RegisterConfirm'),
    meta: {
      layout: 'full',
    },
  },
]
