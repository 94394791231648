import _ from 'lodash'

export default {
  SET_SETTLEMENT(state, payload) {
    state.settlement = _.mergeWith(_.cloneDeep(state.settlementModel), payload)
  },
  SET_SETTLEMENTS(state, payload) {
    state.settlements = payload
  },
  CLEAR_SETTLEMENT(state) {
    state.settlement = _.cloneDeep(state.settlementModel)
  },
}
