import axios from '@/libs/axios'

export default {
  getService(serviceId) {
    return axios.get(`/services/${serviceId}`)
  },
  getServices(params) {
    return axios.get('/services', { params })
  },
  postService(payload) {
    return axios.post('/services', payload)
  },
  patchService(serviceId, payload) {
    return axios.patch(`/services/${serviceId}`, payload)
  },
  putService(serviceId, payload) {
    return axios.put(`/services/${serviceId}`, payload)
  },
  getStatistics(serviceId, params) {
    return axios.get(`/services/${serviceId}/statistics`, { params })
  },
}
