import { cloneNested } from '@/helpers/helpers'

const attachmentModel = {
  status: null,
  amount: null,
  method: null,
  attachmentDate: null,
  comment: null,
}

export default {
  attachment: cloneNested(attachmentModel),
  attachmentModel: cloneNested(attachmentModel),
  attachments: [],
}
