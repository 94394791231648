import axios from '@/libs/axios'

export default {
  getAttachment(attachmentId) {
    return axios.get(`/attachments/${attachmentId}`)
  },
  getAttachments() {
    return axios.get('/attachments')
  },
  getAttachmentsByResource(resource, resourceId) {
    return axios.get(`/attachments/by/${resource}/${resourceId}`)
  },
  postAttachment(type, resource, resourceId, payload) {
    return axios.post(`/attachments/${type}/to/${resource}/${resourceId}`, payload)
  },
  patchAttachment(attachmentId, type, resource, payload) {
    return axios.patch(`/attachments/${type}/${resource}/${attachmentId}`, payload)
  },
  deleteAttachment(attachmentId) {
    return axios.delete(`/attachments/${attachmentId}`)
  },
  getDownloadAttachmentFile(attachmentId) {
    return axios.get(`/attachments/${attachmentId}/download`, { responseType: 'blob' })
  },
  postUploadFile(file, attachmentOrganizationId, progressCallback) {
    const data = new FormData()
    data.append('file', file)
    if (attachmentOrganizationId) {
      data.append('attachmentOrganizationId', attachmentOrganizationId)
    }

    return axios.post(
      '/attachments/file',
      data,
      {
        onUploadProgress: progressCallback,
      },
    )
  },
}
