import { cloneNested } from '@/helpers/helpers'

const customerCalendarEventModel = {
  id: null,
  calendar: {},
  calendarEventSequence: {},
  ordinalNumber: 1,
  calendarEventCustomers: {},
  calendarEventCustomerObjects: {},
  service: null,
  title: null,
  startAt: null,
  endAt: null,
  startedAt: null,
  additionalTimeBefore: 0,
  additionalTimeAfter: 0,
  priceGross: null,
  participantLimit: null,
  script: {},
  attachments: [],
}

export default {
  customerCalendarEvent: cloneNested(customerCalendarEventModel),
  customerCalendarEventModel: cloneNested(customerCalendarEventModel),
  customerCalendarEvents: [],
}
