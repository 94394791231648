import axios from '@/libs/axios'

export default {
  getScript(scriptId) {
    return axios.get(`/scripts/${scriptId}`)
  },
  getScripts() {
    return axios.get('/scripts')
  },
  postScript(payload) {
    return axios.post('/scripts', payload)
  },
  patchScript(scriptId, payload) {
    return axios.patch(`/scripts/${scriptId}`, payload)
  },
  updateScriptsOrder(payload) {
    return axios.post('/scripts/sort', payload)
  },

  getScriptGroup(scriptId) {
    return axios.get(`/scripts/groups/${scriptId}`)
  },
  getScriptGroups() {
    return axios.get('/scripts/groups')
  },
  postScriptGroup(payload) {
    return axios.post('/scripts/groups', payload)
  },
  patchScriptGroup(scriptId, payload) {
    return axios.patch(`/scripts/groups/${scriptId}`, payload)
  },

  getKnowledgeChecks(scriptId) {
    return axios.get(`/scripts/${scriptId}/knowledge-checks`)
  },
  patchKnowledgeChecks(scriptId, payload) {
    return axios.patch(`/scripts/${scriptId}/knowledge-checks`, payload)
  },
  getKnowledgeCheckStatistics(exerciseId) {
    return axios.get(`/scripts/knowledge-checks/${exerciseId}/statistics`)
  },

  getExercises(scriptId) {
    return axios.get(`/scripts/${scriptId}/exercises`)
  },
  patchExercises(scriptId, payload) {
    return axios.patch(`/scripts/${scriptId}/exercises`, payload)
  },
  getExerciseStatistics(exerciseId) {
    return axios.get(`/scripts/exercises/${exerciseId}/statistics`)
  },
}
