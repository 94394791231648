import user from '@/http/requests/customers/user'

export default {
  fetchCustomerDocumentsToAccept: (_, payload) => new Promise((resolve, reject) => {
    user.getCustomerUserDocumentsToAccept(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  acceptCustomerDocument: (_, documentId) => new Promise((resolve, reject) => {
    user.acceptCustomerDocument(documentId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  declineCustomerDocument: (_, documentId) => new Promise((resolve, reject) => {
    user.declineCustomerDocument(documentId)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
