import axios from '@/libs/axios'

export default {
  getCustomer(customerId) {
    return axios.get(`/customers/${customerId}`)
  },
  getCustomers(params) {
    return axios.get('/customers', { params })
  },
  postCustomer(payload) {
    return axios.post('/customers', payload)
  },
  patchCustomer(customerId, payload) {
    return axios.patch(`/customers/${customerId}`, payload)
  },
  putCustomer(customerId, payload) {
    return axios.put(`/customers/${customerId}`, payload)
  },
  getCustomerCalendarEvents(customerId, params) {
    return axios.get(`/customers/${customerId}/calendar-events`, { params })
  },
  getCustomerBalanceHistories(customerId, params) {
    return axios.get(`/customers/${customerId}/balance-histories`, { params })
  },
  getCustomerStatistics(customerId, params) {
    return axios.get(`/customers/${customerId}/statistics`, { params })
  },
}
