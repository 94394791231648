const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo')) || { roles: [] }
const getUserTokens = () => JSON.parse(localStorage.getItem('tokens')) || {}

export default {
  activeUser: getUserInfo(),
  activeUserTokens: getUserTokens(),
  showInitTeamPopup: false,
  fetchingUserInfo: false,
  notifications: [],
  isUserLoggedIn: () => {
    let isAuthenticated = true
    if (!localStorage.getItem('userInfo')) {
      isAuthenticated = false
    }
    return isAuthenticated
  },
}
