import role from '@/auth/role'

export default [
  {
    path: '/organization/dashboard',
    name: 'organization-dashboard',
    component: () => import('@/views/organization/dashboard/Dashboard.vue'),
    meta: {
      title: 'navigation.dashboard',
      access: [role.educator],
      pageTitle: 'navigation.dashboard',
      breadcrumb: [
        {
          text: 'navigation.dashboard',
          active: true,
        },
      ],
    },
  },
]
