import role from '@/auth/role'

export default [
  {
    path: '/organization/script/list',
    name: 'organization-script-list',
    meta: {
      title: 'navigation.scripts',
      pageTitle: 'navigation.scripts',
      access: [role.educator],
      breadcrumb: [
        { text: 'navigation.scripts', active: true },
      ],
    },
    component: () => import('@/views/organization/script/scripts/ScriptList'),
  },
  {
    path: '/organization/script/add',
    name: 'organization-script-add',
    meta: {
      title: 'script.breadcrumb.script-add',
      pageTitle: 'script.breadcrumb.scripts',
      access: [role.educator],
      breadcrumb: [
        { text: 'script.breadcrumb.scripts', to: { name: 'organization-script-list' } },
        { text: 'script.breadcrumb.script-add', active: true },
      ],
    },
    component: () => import('@/views/organization/script/scripts/ScriptForm'),
  },
  {
    path: '/organization/script/edit/:id',
    name: 'organization-script-edit',
    meta: {
      title: 'script.breadcrumb.script-edit',
      pageTitle: 'script.breadcrumb.script-edit',
      access: [role.educator],
      breadcrumb: [
        { text: 'script.breadcrumb.scripts', to: { name: 'organization-script-list' } },
        { text: 'script.breadcrumb.script-edit', active: true },
      ],
    },
    component: () => import('@/views/organization/script/scripts/ScriptForm'),
  },
  {
    path: '/organization/script/group/list',
    name: 'organization-script-groups',
    meta: {
      title: 'script.breadcrumb.script-groups',
      pageTitle: 'script.breadcrumb.script-groups',
      access: [role.educator],
      breadcrumb: [
        { text: 'script.breadcrumb.scripts', to: { name: 'organization-script-list' } },
        { text: 'script.breadcrumb.groups', active: true },
      ],
    },
    component: () => import('@/views/organization/script/groups/ScriptGroupList'),
  },
]
