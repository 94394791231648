import axios from '@/libs/axios'

export default {
  getCalendarEvent(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}`)
  },
  getCalendarStatistics(type, params) {
    return axios.get(`/calendars/statistics/${type}`, { params })
  },
  getCalendarEvents(params) {
    return axios.get('/calendars/events', { params })
  },
  getCalendarEventsByCalendarId(calendarId, params) {
    return axios.get(`/calendars/events/by-calendar/${calendarId}`, { params })
  },
  postCalendarEvent(payload, params) {
    return axios.post('/calendars/events', payload, { params })
  },
  patchCalendarEvent(calendarEventId, payload, params) {
    return axios.patch(`/calendars/events/${calendarEventId}`, payload, { params })
  },
  getCalendarEventViewNavigation(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}/view-navigation`)
  },
  putCalendarEvent(calendarEventId, payload) {
    return axios.put(`/calendars/events/${calendarEventId}`, payload)
  },
  getCalendarEventCollisions({ params }) {
    return axios.get('/calendars/events/collisions', { params })
  },

  setCalendarEventCustomerPresence(calendarEventCustomerId, presence) {
    return axios.post(`/calendars/event-customers/${calendarEventCustomerId}/set-presence/${presence}`)
  },

  getCalendarEventSummary(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}/get-summary`)
  },
  confirmCalendarEventSummary(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}/confirm-summary`)
  },

  patchCalendarEventCustomer(calendarEventCustomerId, data) {
    return axios.patch(`/calendars/event-customers/${calendarEventCustomerId}`, data)
  },
  getCalendarEventPreviousExercises(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}/previous/exercises`)
  },
  addExerciseResult(calendarEventCustomerId, exerciseId) {
    return axios.post(`/calendars/event-customers/${calendarEventCustomerId}/exercise-results/${exerciseId}`)
  },
  removeExerciseResult(calendarEventCustomerId, exerciseId) {
    return axios.delete(`/calendars/event-customers/${calendarEventCustomerId}/exercise-results/${exerciseId}`)
  },
  patchExerciseResult(exerciseResultId, payload, params) {
    return axios.patch(`/scripts/exercises/results/${exerciseResultId}`, payload, { params })
  },
  getCalendarEventPreviousKnowledgeChecks(calendarEventId) {
    return axios.get(`/calendars/events/${calendarEventId}/previous/knowledge-checks`)
  },
  addKnowledgeCheckRating(calendarEventCustomerId, knowledgeCheckId) {
    return axios.post(`/calendars/event-customers/${calendarEventCustomerId}/knowledge-check-ratings/${knowledgeCheckId}`)
  },
  removeKnowledgeCheckRating(calendarEventCustomerId, knowledgeCheckId) {
    return axios.delete(`/calendars/event-customers/${calendarEventCustomerId}/knowledge-check-ratings/${knowledgeCheckId}`)
  },
  patchKnowledgeCheckRating(knowledgeCheckRatingId, payload, params) {
    return axios.patch(`/scripts/knowledge-checks/ratings/${knowledgeCheckRatingId}`, payload, { params })
  },

  cancelCalendarEvent(calendarEventId, customerId, params) {
    let url = `/calendars/events/${calendarEventId}/cancel`
    if (customerId) url = `/calendars/events/${calendarEventId}/customers/${customerId}/cancel`
    return axios.patch(url, {}, { params })
  },
}
