<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useCssVar, useWindowSize } from '@vueuse/core'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import _ from 'lodash'
import store from '@/store'
import login from '@/http/requests/auth/login'
import router from '@/router'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')

    window.toast = Object.freeze({
      notify: {
        success: (header, message, options) => {
          const defaultOptions = {
            timeout: 5000,
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: header,
              text: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          _.merge(defaultOptions, options))
        },
        warning: (header, message, options) => {
          const defaultOptions = {
            timeout: 5000,
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: header,
              text: message,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
            },
          },
          _.merge(defaultOptions, options))
        },
        danger: (header, message, options) => {
          const defaultOptions = {
            timeout: false,
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: header,
              text: message,
              icon: 'XIcon',
              variant: 'danger',
            },
          },
          _.merge(defaultOptions, options))
        },
      },
    })
  },
  beforeMount() {
    setTimeout(() => {
      if (this.$route.meta.access && this.$route.meta.access.length) {
        this.fetchUserInfo()
      }
    }, 500)
  },
  methods: {
    fetchUserInfo() {
      this.$store.dispatch('auth/fetchUserInfo')
        .catch(err => {
          if (err?.code === 403) {
            this.logout()
          }
        })
    },
    logout() {
      if (this.me.id) {
        this.$store.dispatch('auth/logout')
          .finally(() => {
            router.push({ name: 'login' })
            window.toast.notify.success(this.$t('auth.logout-success'))
          })
      } else {
        router.push({ name: 'login' })
      }
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    login.init(store)

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}

</script>
