import role from '@/auth/role'

export default [
  {
    path: '/organization/wizard',
    name: 'organization-wizard',
    component: () => import('@/views/organization/wizard/ProfileWizard.vue'),
    meta: {
      title: 'navigation.wizard',
      layout: 'full',
      access: [role.admin],
    },
  },
]
